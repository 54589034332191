<template>
  <div id="app">
    <HeaderTop></HeaderTop>
    <router-link to="/"></router-link>
    <router-view />
    <FooterButtom v-show="$route.path != '/'"></FooterButtom>
    <FooterButtomTwo v-show="$route.path == '/'"></FooterButtomTwo>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderTop from '@/components/HeaderTop.vue';
import FooterButtom from '@/components/FooterButtom.vue';
import FooterButtomTwo from '@/components/FooterButtomTwo.vue';

export default {
  name: 'Index',
  components: {
    HeaderTop,
    FooterButtom,
    FooterButtomTwo,
  },
};
</script>

<style lang="less" scoped></style>
<style lang="less">
#app {
  padding-top: 79px;
}
@media screen and (max-width: 768px) {
  #app {
    padding-top: 0px;
  }
}
// 轮播图白底
.swiper_bg_white {
  width: 614px;
  margin-top: 59px;
  .swiper-pagination-bullet {
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    background: rgb(51, 51, 51);
    border-radius: 50%;
    margin: 0rem 15px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
  }
}
@media screen and (max-width: 768px) {
  .swiper_bg_white {
    width: 6.14rem;
    margin-top: 0.59rem;
    .swiper-pagination-bullet {
      box-sizing: border-box;
      width: 0.15rem;
      height: 0.15rem;
      background: rgb(51, 51, 51);
      border-radius: 50%;
      margin: 0rem 0.15rem;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
    }
  }
}
// 轮播图蓝底
.swiper_bg_blue {
  width: 560px;
  margin-top: 45px;
  .swiper-pagination-bullet {
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background: #f1f1f1;
    border-radius: 50%;
    margin: 0rem 15px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    border: 1px solid #ffffff;
    background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
  }
}
@media screen and (max-width: 768px) {
  .swiper_bg_blue {
    width: 5.6rem;
    margin-top: 0.45rem;
    .swiper-pagination-bullet {
      box-sizing: border-box;
      width: 0.18rem;
      height: 0.18rem;
      background: #f1f1f1;
      border-radius: 50%;
      margin: 0rem 0.15rem;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      border: 1px solid #ffffff;
      background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
    }
  }
}
// 标题样式
.part-title {
  font-family: FontExcellent;
  font-size: 36px;
  color: #333333;
  display: block;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .part-title {
    font-size: 0.3rem;
  }
}
</style>
